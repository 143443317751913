import React from "react";
import "./Switch.css";

import cx from "classnames";
import { Switchlabel, SwitchInput, SwitchSpan } from "./SwitchElements";

const Switch = ({
  rounded = false,
  isToggled,
  onToggle,
  bgColorChecked,
  roundColorChecked,
  border,
  bgColorUnChecked,
  roundColorUnChecked,
  wiDth,
  marLeft,
  themeColor,
}) => {
  const sliderCX = cx("slider_slide", {
    rounded: rounded,
  });

  return (
    <>
      <Switchlabel wiDth={wiDth}>
        <SwitchInput
          checked={isToggled}
          onChange={onToggle}
          bgColorChecked={bgColorChecked}
          roundColorChecked={roundColorChecked}
        />
        <SwitchSpan
          themeColor={themeColor}
          className={sliderCX}
          border={border}
          bgColorUnChecked={bgColorUnChecked}
          marLeft={marLeft}
          roundColorUnChecked={roundColorUnChecked}
        />
      </Switchlabel>

      {/*<label className="switch">
            <input type="checkbox"checked={isToggled} onChange={onToggle}/>
            <span className={sliderCX}/>
            </label>*/}
    </>
  );
};

export default Switch;
