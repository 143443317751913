import React, { useEffect, useState } from "react";
import * as SS from "../../styles/SidebarStyled";
import * as NS from "../../styles/NavStyled";
import * as CS from "../../styles/CommonStyled";
import mainLogo from "../../assets/images/rainLightSvg.svg";
import darkLogo from "../../assets/images/rainDarkSvg.svg";
import frameLogo from "../../assets/images/frame_logo.png";
import arrowLogo from "../../assets/images/arrowLogo.png";
import arrowLogo1 from "../../assets/images/arrowLogo1.png";
import logoutLogo from "../../assets/images/logoutIcon.png";
import downArrowSide from "../../assets/images/downArrowSide.png";
import darkArrowSide from "../../assets/images/darkArrowSide.png";
import downArrow from "../../assets/images/downArrow.png";
import darkTopAr from "../../assets/images/darkTopAr.png";
import crossGrey from "../../assets/images/crossGrey.png";
import bellIcon from "../../assets/images/bell.png";
import bellDarkIcon from "../../assets/images/bellDark.png";
import { SidebarData } from "./SidebarData";
import { useSelector, useDispatch } from "react-redux";
import lightSun from "../../assets/images/light.png";
import lightMoon from "../../assets/images/dark.png";
import {
  setSidebarToggle,
  setUserData,
  setSidebarIsOpens,
  setTableLength,
  setPageCount,
  setFirstMenu,
  setSecondMenu,
  setCourseLangId,
  setCourseMasterId,
  setCourseTypo,
  setEpisodeDatao,
  setIsDark,
} from "../../store/action";
import AvatarIcon from "../../assets/images/profileDemo.png";
import { AccessCheck, SubAccessCheck } from "../../utils/Helpers";
import CryptoJS from "crypto-js";
import * as SEC from "../../styles/SectionStyled";
import warnIcon from "../../assets/images/warn.png";
import { ModalSuccess } from "../Modal/ModalSuccess";
import successModal from "../../assets/images/successModal.png";
import Switch from "../../components/Switch/Switch";

import { Modal } from "../../components/Modal/Modal";
import { RaisaTickto } from "../../utils/api";
import axios from "../../utils/axios";

const secretKeyMain = "rmAdminSecretKey";

const Sidebar = ({ navigate }) => {
  const sidebarToggle = useSelector((state) => state?.isSidebarToggle);
  const sidebarIsOpens = useSelector((state) => state?.sidebarIsOpen);
  const profileData = useSelector((state) => state?.profileData);
  const [isOpen, setIsOpen] = useState(true);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(sidebarIsOpens);
  const toggle = () => setIsOpen(!isOpen);
  const [opens, setOpens] = useState(false);
  const [showsucess, setShowsucess] = useState(false);

  const theme = useSelector((state) => state?.isDark);
  const userData = useSelector((state) => state?.userData);
  // const [isActive, setIsActive] = useState({});
  const [ticketCount, setTicketCount] = useState(0);
  const [isActive, setIsActive] = useState(() => {
    // Load submenu state from localStorage or default to an empty object
    const storedState = localStorage.getItem("submenuState");
    return storedState ? JSON.parse(storedState) : {};
  });

  useEffect(() => {
    // Save the submenu state to localStorage whenever it changes
    localStorage.setItem("submenuState", JSON.stringify(isActive));
  }, [isActive]);
  const dispatch = useDispatch();

  const handleConfirm = () => {
    setOpens(false);
    setShowsucess(true);
    setTimeout(() => {
      setShowsucess(false);
      localStorage.removeItem("rainDataToken");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("rainRMUserData");
      localStorage.removeItem("isToggle");
      localStorage.removeItem("firstMenuActive");
      localStorage.removeItem("secondMenuActive");
      localStorage.removeItem("pageCount");
      localStorage.removeItem("rainTheme");
      localStorage.removeItem("submenuState");
      dispatch(setUserData(""));
      navigate("/");
    }, 2000);
  };
  useEffect(() => {
    setSidebarIsOpen(sidebarIsOpens);
  }, [sidebarIsOpens]);
  const toggleIsActive = (event, index) => {
    event.stopPropagation();
    setIsActive((prevIsActive) => {
      // Check if prevIsActive is null or not an object and initialize correctly
      if (!prevIsActive || typeof prevIsActive !== "object") {
        console.error("prevIsActive is invalid:", prevIsActive);
        // Optionally initialize to an empty object or based on logic
        prevIsActive = {};
      }

      // Toggle the active state for the given index
      const newState = {
        ...prevIsActive,
        [index]: !prevIsActive[index],
      };

      console.log(
        `Toggling index ${index}: Current State: ${prevIsActive[index]}, New State: ${newState[index]}`
      );
      return newState;
    });
  };

  const encryptedFirstMenuActive = CryptoJS.AES.encrypt(
    JSON.stringify({
      isClient: true,
      isBilling: false,
      isMicrosite: false,
      isCourses: false,
      isSettings: false,
      isClientAdminSpoc: false,
    }),
    secretKeyMain
  ).toString();
  const encryptedSecondMenuActive = CryptoJS.AES.encrypt(
    JSON.stringify({
      courseDetail: true,
      learnerDetail: false,
      policyDocuments: false,
      courseAssignments: false,
    }),
    secretKeyMain
  ).toString();

  const filteredSidebarData = SidebarData?.filter(
    (val) => val.title !== "Configuration"
  );

  const handleCloses = () => {
    setOpens(false);
    setShowsucess(false);
  };

  const openTicket = async (e) => {
    try {
      const url = `${RaisaTickto.ticketCount}`;
      const { data } = await axios.get(url);
      setTicketCount(data?.data[0]?.unread_ticket);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    openTicket();
  }, []);

  console.log(ticketCount, "ticketCount ticketCount")
  return (
    <>
      <SS.SidebarContainer themeColor={theme}>
        <SS.InnerSidebar themeColor={theme}>
          <SS.LogoListBox>
            <SS.LogoRow>
              {isOpen ? null : (
                <SS.HideLogo>
                  <SS.FrameLogoContainer>
                    <img src={frameLogo} alt="Frame Logo" />
                  </SS.FrameLogoContainer>
                  <SS.ArrowLogo1
                    src={arrowLogo1}
                    alt=""
                    onClick={() => {
                      toggle();
                      dispatch(setSidebarToggle(false));
                      localStorage.setItem("isSidebarToggle", false);
                    }}
                    style={{ display: isOpen ? "none" : "flex" }}
                  />
                </SS.HideLogo>
              )}

              {theme ? (
                <SS.MainLogoTwo>
                  <img
                    src={darkLogo}
                    alt=""
                    style={{ display: isOpen ? "flex" : "none" }}
                  />
                </SS.MainLogoTwo>
              ) : (
                <SS.MainLogoTwo>
                  <img
                    src={mainLogo}
                    alt=""
                    style={{ display: isOpen ? "flex" : "none", width: "100%" }}
                  />
                </SS.MainLogoTwo>
              )}

              <SS.ArrowLogo
                src={arrowLogo}
                alt=""
                onClick={() => {
                  toggle();
                  dispatch(setSidebarToggle(true));
                  localStorage.setItem("isSidebarToggle", true);
                }}
                style={{ display: isOpen ? "block" : "none" }}
              />
            </SS.LogoRow>
            <SS.SidebarList>
              {userData?.isSuperAdmin
                ? SidebarData?.map((val, ind) => {
                    const isSubMenuActive =
                      isActive && isActive[ind] ? !!isActive[ind] : false; // Check if the submenu at this index is active

                    return (
                      <SS.MenuBox key={ind}>
                        <SS.MenuRow
                          themeColor={theme}
                          activeColor={val.activeTab?.includes(
                            window.location.pathname
                          )}
                          onClick={() => {
                            if (val.subMenu) {
                              setIsActive((prev) => {
                                const newState = { ...prev, [ind]: !prev[ind] };
                                localStorage.setItem(
                                  "submenuState",
                                  JSON.stringify(newState)
                                ); // Store submenu state in localStorage
                                return newState;
                              });
                              dispatch(setTableLength([]));

                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                            } else {
                              // Close all submenus when navigating directly from a menu item
                              setIsActive({});
                              navigate(val.path);
                              dispatch(setTableLength([]));
                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                              localStorage.setItem(
                                "firstMenuActive",
                                encryptedFirstMenuActive
                              );
                              localStorage.setItem(
                                "secondMenuActive",
                                encryptedSecondMenuActive
                              );
                              localStorage.removeItem("mainClientId");
                              dispatch(
                                setFirstMenu({
                                  isClient: true,
                                  isBilling: false,
                                  isMicrosite: false,
                                  isCourses: false,
                                  isSettings: false,
                                  isNotes: false,
                                })
                              );
                              dispatch(
                                setSecondMenu({
                                  courseDetail: true,
                                  learnerDetail: false,
                                  policyDocuments: false,
                                  committee: false,
                                  isReminder: false,
                                  courseAssignments: false,
                                })
                              );
                              dispatch(setCourseLangId(null));
                              dispatch(setCourseMasterId(null));
                              dispatch(setCourseTypo(false));
                              dispatch(setEpisodeDatao(null));
                            }
                          }}
                        >
                          <div
                            className="row-wise"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                              if (!val.subMenu) {
                                navigate(val.path);
                              }
                              setIsActive((prev) => {
                                const newState = { ...prev, [ind]: !prev[ind] };
                                localStorage.setItem(
                                  "submenuState",
                                  JSON.stringify(newState)
                                ); // Store submenu state
                                return newState;
                              });
                              dispatch(setTableLength([]));
                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                            }}
                          >
                            <img
                              src={
                                val.activeTab?.includes(
                                  window.location.pathname
                                )
                                  ? val.activeIcon
                                  : val.icon
                              }
                              alt=""
                            />
                            {isOpen && val.title} {val.title === "Support" ? <span style={{ borderRadius: "50%", padding: ticketCount > 9 ? "1px 2px" : "0 4px", marginLeft: "6px"  }}> ->{ticketCount}</span> : ""}
                          </div>
                          {val.subMenu && (
                            <img
                              className="arrow-img"
                              src={
                                isSubMenuActive
                                  ? val.activeTab?.includes(
                                      window.location.pathname
                                    )
                                    ? darkArrowSide
                                    : darkTopAr
                                  : downArrow
                              }
                              alt=""
                            />
                          )}
                        </SS.MenuRow>
                        {isSubMenuActive && (
                          <SS.SubMenuBox
                            isActive={isSubMenuActive}
                            themeColor={theme}
                          >
                            {val.subMenu?.map((subItem, subIndex) => (
                              <SS.ActiveSub
                                themeColor={theme}
                                key={subIndex}
                                activeColor={subItem.activePathTab?.includes(
                                  window.location.pathname
                                )}
                                onClick={() => {
                                  navigate(subItem.pathName);
                                  dispatch(setTableLength([]));

                                  dispatch(setPageCount(1));
                                  localStorage.setItem("pageCount", 1);
                                  localStorage.setItem(
                                    "firstMenuActive",
                                    encryptedFirstMenuActive
                                  );
                                  localStorage.removeItem("mainClientId");
                                  localStorage.setItem(
                                    "secondMenuActive",
                                    encryptedSecondMenuActive
                                  );
                                  dispatch(
                                    setFirstMenu({
                                      isClient: true,
                                      isBilling: false,
                                      isMicrosite: false,
                                      isCourses: false,
                                      isSettings: false,
                                      isClientAdminSpoc: false,
                                      isNotes: false,
                                    })
                                  );
                                  dispatch(
                                    setSecondMenu({
                                      courseDetail: true,
                                      learnerDetail: false,
                                      policyDocuments: false,
                                      committee: false,
                                      isReminder: false,
                                      courseAssignments: false,
                                    })
                                  );

                                  dispatch(setCourseLangId(null));
                                  dispatch(setCourseMasterId(null));
                                  dispatch(setCourseTypo(false));
                                  dispatch(
                                    setEpisodeDatao({
                                      title: "",
                                      url: "",
                                      fileSize: "",
                                      status: "",
                                      courseEpisodeeName: "",
                                      courseDescription: "",
                                      courseTags: [],
                                      videoTime: "",
                                    })
                                  );
                                }}
                              >
                                {subItem.label}
                              </SS.ActiveSub>
                            ))}
                          </SS.SubMenuBox>
                        )}
                      </SS.MenuBox>
                    );
                  })
                : ""}
              {!userData?.isSuperAdmin &&
                filteredSidebarData.map((val, ind) => {
                  const isSubMenuActive = !!isActive[ind];
                  return AccessCheck(val?.key) ? (
                    <SS.MenuBox key={ind}>
                      <SS.MenuRow
                        themeColor={theme}
                        activeColor={val.activeTab?.includes(
                          window.location.pathname
                        )}
                        onClick={() => {
                          if (val.subMenu) {
                            // Toggle the submenu for this specific index
                            setIsActive((prev) => {
                              const newState = { ...prev, [ind]: !prev[ind] };
                              localStorage.setItem(
                                "submenuState",
                                JSON.stringify(newState)
                              ); // Store submenu state in localStorage
                              return newState;
                            });
                            dispatch(setTableLength([]));

                            dispatch(setPageCount(1));
                            localStorage.setItem("pageCount", 1);
                          } else {
                            // Close all submenus when navigating directly from a menu item
                            setIsActive({});
                            navigate(val.path);
                            dispatch(setTableLength([]));
                            dispatch(setPageCount(1));
                            localStorage.setItem("pageCount", 1);
                            localStorage.setItem(
                              "firstMenuActive",
                              encryptedFirstMenuActive
                            );
                            localStorage.setItem(
                              "secondMenuActive",
                              encryptedSecondMenuActive
                            );
                            localStorage.removeItem("mainClientId");
                            dispatch(
                              setFirstMenu({
                                isClient: true,
                                isBilling: false,
                                isMicrosite: false,
                                isCourses: false,
                                isSettings: false,
                                isClientAdminSpoc: false,
                                isNotes: false,
                              })
                            );
                            dispatch(
                              setSecondMenu({
                                courseDetail: true,
                                learnerDetail: false,
                                policyDocuments: false,
                                committee: false,
                                isReminder: false,
                                courseAssignments: false,
                              })
                            );

                            dispatch(setCourseLangId(null));
                            dispatch(setCourseMasterId(null));
                            dispatch(setCourseTypo(false));
                            dispatch(setEpisodeDatao(null));
                          }
                        }}
                      >
                        <div
                          className="row-wise"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                            if (!val.subMenu) {
                              navigate(val.path);
                            }
                            setIsActive((prev) => {
                              const newState = { ...prev, [ind]: !prev[ind] };
                              localStorage.setItem(
                                "submenuState",
                                JSON.stringify(newState)
                              ); // Store submenu state
                              return newState;
                            });
                            dispatch(setTableLength([]));
                            dispatch(setPageCount(1));
                            localStorage.setItem("pageCount", 1);
                          }}
                        >
                          <img
                            src={
                              val.activeTab?.includes(window.location.pathname)
                                ? val.activeIcon
                                : val.icon
                            }
                            alt=""
                          />
                          {isOpen && val.title} {val.title === "Support" ? <span style={{ borderRadius: "50%", padding: ticketCount > 9 ? "1px 2px" : "0 4px", marginLeft: "6px"  }}> ->{ticketCount}</span> : ""}
                        </div>
                        {val.subMenu && (
                          <img
                            className="arrow-img"
                            src={
                              isSubMenuActive
                                ? val.activeTab?.includes(
                                    window.location.pathname
                                  )
                                  ? darkArrowSide
                                  : darkTopAr
                                : downArrow
                            }
                            alt=""
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick

                              localStorage.setItem(
                                "firstMenuActive",
                                encryptedFirstMenuActive
                              );

                              localStorage.removeItem("mainClientId");
                              localStorage.setItem(
                                "secondMenuActive",
                                encryptedSecondMenuActive
                              );
                              dispatch(
                                setFirstMenu({
                                  isClient: true,
                                  isBilling: false,
                                  isMicrosite: false,
                                  isCourses: false,
                                  isSettings: false,
                                  isClientAdminSpoc: false,
                                  isNotes: false,
                                })
                              );
                              dispatch(
                                setSecondMenu({
                                  courseDetail: true,
                                  learnerDetail: false,
                                  policyDocuments: false,
                                  committee: false,
                                  isReminder: false,
                                  courseAssignments: false,
                                })
                              );

                              dispatch(setCourseLangId(null));
                              dispatch(setCourseMasterId(null));
                              dispatch(setCourseTypo(false));
                              dispatch(
                                setEpisodeDatao({
                                  title: "",
                                  url: "",
                                  fileSize: "",
                                  status: "",
                                  courseEpisodeeName: "",
                                  courseDescription: "",
                                  courseTags: [],
                                  videoTime: "",
                                })
                              );
                            }}
                          />
                        )}
                      </SS.MenuRow>
                      {isSubMenuActive && (
                        <SS.SubMenuBox
                          isActive={isSubMenuActive}
                          themeColor={theme}
                        >
                          {val.subMenu?.map((subItem, subIndex) => {
                            return SubAccessCheck(subItem.key) ? (
                              <SS.ActiveSub
                                themeColor={theme}
                                key={subIndex}
                                activeColor={subItem.activePathTab?.includes(
                                  window.location.pathname
                                )}
                                onClick={() => {
                                  navigate(subItem.pathName);
                                  dispatch(setTableLength([]));
                                  dispatch(setPageCount(1));
                                  localStorage.setItem("pageCount", 1);
                                }}
                              >
                                {subItem.label}
                              </SS.ActiveSub>
                            ) : null;
                          })}
                        </SS.SubMenuBox>
                      )}
                    </SS.MenuBox>
                  ) : null;
                })}
            </SS.SidebarList>
            {/* {userData?.rm_admin === 1 &&
              SidebarData?.map((val, ind) =>
                userData?.permissions[val.moduleName] ? (
                  <SS.MenuRow
                    activeColor={val.activeTab?.includes(
                      window.location.pathname
                    )}
                    themeColor={theme}
                    key={ind}
                    onClick={() => navigate(val.path)}
                  >
                    <img
                      src={
                        val.activeTab?.includes(window.location.pathname)
                          ? val.activeIcon
                          : val.icon
                      }
                      alt=""
                    />
                    {val.title}
                  </SS.MenuRow>
                ) : null
              )} */}
          </SS.LogoListBox>
          <SS.LogOutBox
            themeColor={theme}
            onClick={() => {
              setOpens(true);
            }}
          >
            <img src={logoutLogo} alt="" />
            {isOpen && <span>Log Out</span>}
          </SS.LogOutBox>
        </SS.InnerSidebar>
      </SS.SidebarContainer>

      {sidebarIsOpen ? (
        <SS.SidebarContainerResp
          sidebarIsOpen={sidebarIsOpen}
          themeColor={theme}
        >
          <SS.InnerSidebar themeColor={theme}>
            <SS.LogoListBox>
              <SS.LogoRow>
                <SS.MainLogo>
                  <img
                    src={theme ? darkLogo : mainLogo}
                    alt=""
                    style={{ display: isOpen ? "block" : "none" }}
                  />
                </SS.MainLogo>
                <SS.ArrowLogoResp
                  src={crossGrey}
                  alt=""
                  onClick={() => {
                    setSidebarIsOpen(false);
                    dispatch(setSidebarIsOpens(false));
                  }}
                />
              </SS.LogoRow>
              <SS.SidebarList>
                {userData?.isSuperAdmin
                  ? SidebarData?.map((val, ind) => {
                      const isSubMenuActive =
                        isActive && isActive[ind] ? !!isActive[ind] : false; // Check if the submenu at this index is active

                      return (
                        <SS.MenuBox key={ind}>
                          <SS.MenuRow
                            themeColor={theme}
                            activeColor={val.activeTab?.includes(
                              window.location.pathname
                            )}
                            onClick={() => {
                              if (val.subMenu) {
                                // Toggle the submenu for this specific index
                                setIsActive((prev) => {
                                  const newState = {
                                    ...prev,
                                    [ind]: !prev[ind],
                                  };
                                  localStorage.setItem(
                                    "submenuState",
                                    JSON.stringify(newState)
                                  ); // Store submenu state in localStorage
                                  return newState;
                                });
                                dispatch(setTableLength([]));

                                dispatch(setPageCount(1));
                                localStorage.setItem("pageCount", 1);
                              } else {
                                // Close all submenus when navigating directly from a menu item
                                setIsActive({});
                                navigate(val.path);
                                dispatch(setTableLength([]));
                                dispatch(setPageCount(1));

                                setSidebarIsOpen(false);
                                dispatch(setSidebarIsOpens(false));
                                localStorage.setItem("pageCount", 1);
                                localStorage.setItem(
                                  "firstMenuActive",
                                  encryptedFirstMenuActive
                                );
                                localStorage.setItem(
                                  "secondMenuActive",
                                  encryptedSecondMenuActive
                                );
                                localStorage.removeItem("mainClientId");
                                dispatch(
                                  setFirstMenu({
                                    isClient: true,
                                    isBilling: false,
                                    isMicrosite: false,
                                    isCourses: false,
                                    isSettings: false,
                                    isNotes: false,
                                  })
                                );
                                dispatch(
                                  setSecondMenu({
                                    courseDetail: true,
                                    learnerDetail: false,
                                    policyDocuments: false,
                                    committee: false,
                                    isReminder: false,
                                    courseAssignments: false,
                                  })
                                );
                                dispatch(setCourseLangId(null));
                                dispatch(setCourseMasterId(null));
                                dispatch(setCourseTypo(false));
                                dispatch(setEpisodeDatao(null));
                              }
                            }}
                          >
                            <div
                              className="row-wise"
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                                if (!val.subMenu) {
                                  navigate(val.path);
                                  setSidebarIsOpen(false);
                                  dispatch(setSidebarIsOpens(false));
                                }
                                setIsActive((prev) => {
                                  const newState = {
                                    ...prev,
                                    [ind]: !prev[ind],
                                  };
                                  localStorage.setItem(
                                    "submenuState",
                                    JSON.stringify(newState)
                                  ); // Store submenu state
                                  return newState;
                                });
                                dispatch(setTableLength([]));
                                dispatch(setPageCount(1));
                                localStorage.setItem("pageCount", 1);
                              }}
                            >
                              <img
                                src={
                                  val.activeTab?.includes(
                                    window.location.pathname
                                  )
                                    ? val.activeIcon
                                    : val.icon
                                }
                                alt=""
                              />
                              {isOpen && val.title} {val.title === "Support" ? <span style={{ borderRadius: "50%", padding: ticketCount > 9 ? "1px 2px" : "0 4px", marginLeft: "6px"  }}> ->{ticketCount}</span> : ""}
                            </div>
                            {val.subMenu && (
                              <img
                                className="arrow-img"
                                src={
                                  isSubMenuActive
                                    ? val.activeTab?.includes(
                                        window.location.pathname
                                      )
                                      ? darkArrowSide
                                      : darkTopAr
                                    : downArrow
                                }
                                alt=""
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                                }}
                              />
                            )}
                          </SS.MenuRow>
                          {isSubMenuActive && (
                            <SS.SubMenuBox
                              isActive={isSubMenuActive}
                              themeColor={theme}
                            >
                              {val.subMenu?.map((subItem, subIndex) => (
                                <SS.ActiveSub
                                  themeColor={theme}
                                  key={subIndex}
                                  activeColor={subItem.activePathTab?.includes(
                                    window.location.pathname
                                  )}
                                  onClick={() => {
                                    navigate(subItem.pathName);
                                    setSidebarIsOpen(false);
                                    dispatch(setSidebarIsOpens(false));
                                    dispatch(setTableLength([]));

                                    dispatch(setPageCount(1));
                                    localStorage.setItem("pageCount", 1);
                                    localStorage.setItem(
                                      "firstMenuActive",
                                      encryptedFirstMenuActive
                                    );
                                    localStorage.removeItem("mainClientId");
                                    localStorage.setItem(
                                      "secondMenuActive",
                                      encryptedSecondMenuActive
                                    );
                                    dispatch(
                                      setFirstMenu({
                                        isClient: true,
                                        isBilling: false,
                                        isMicrosite: false,
                                        isCourses: false,
                                        isSettings: false,
                                        isClientAdminSpoc: false,
                                        isNotes: false,
                                      })
                                    );
                                    dispatch(
                                      setSecondMenu({
                                        courseDetail: true,
                                        learnerDetail: false,
                                        policyDocuments: false,
                                        committee: false,
                                        isReminder: false,
                                        courseAssignments: false,
                                      })
                                    );

                                    dispatch(setCourseLangId(null));
                                    dispatch(setCourseMasterId(null));
                                    dispatch(setCourseTypo(false));
                                    dispatch(
                                      setEpisodeDatao({
                                        title: "",
                                        url: "",
                                        fileSize: "",
                                        status: "",
                                        courseEpisodeeName: "",
                                        courseDescription: "",
                                        courseTags: [],
                                        videoTime: "",
                                      })
                                    );
                                  }}
                                >
                                  {subItem.label}
                                </SS.ActiveSub>
                              ))}
                            </SS.SubMenuBox>
                          )}
                        </SS.MenuBox>
                      );
                    })
                  : ""}
                {!userData?.isSuperAdmin &&
                  SidebarData?.map((val, ind) => {
                    const isSubMenuActive = !!isActive[ind];
                    return AccessCheck(val?.key) ? (
                      <SS.MenuBox key={ind}>
                        <SS.MenuRow
                          themeColor={theme}
                          activeColor={val.activeTab?.includes(
                            window.location.pathname
                          )}
                          onClick={() => {
                            if (val.subMenu) {
                              // Toggle the submenu for this specific index
                              setIsActive((prev) => {
                                const newState = { ...prev, [ind]: !prev[ind] };
                                localStorage.setItem(
                                  "submenuState",
                                  JSON.stringify(newState)
                                ); // Store submenu state in localStorage
                                return newState;
                              });
                              dispatch(setTableLength([]));

                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                            } else {
                              // Close all submenus when navigating directly from a menu item
                              setIsActive({});
                              navigate(val.path);
                              setSidebarIsOpen(false);
                              dispatch(setSidebarIsOpens(false));
                              dispatch(setTableLength([]));
                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                              localStorage.setItem(
                                "firstMenuActive",
                                encryptedFirstMenuActive
                              );
                              localStorage.setItem(
                                "secondMenuActive",
                                encryptedSecondMenuActive
                              );
                              localStorage.removeItem("mainClientId");
                              dispatch(
                                setFirstMenu({
                                  isClient: true,
                                  isBilling: false,
                                  isMicrosite: false,
                                  isCourses: false,
                                  isSettings: false,
                                  isClientAdminSpoc: false,
                                  isNotes: false,
                                })
                              );
                              dispatch(
                                setSecondMenu({
                                  courseDetail: true,
                                  learnerDetail: false,
                                  policyDocuments: false,
                                  committee: false,
                                  isReminder: false,
                                  courseAssignments: false,
                                })
                              );

                              dispatch(setCourseLangId(null));
                              dispatch(setCourseMasterId(null));
                              dispatch(setCourseTypo(false));
                              dispatch(setEpisodeDatao(null));
                            }
                          }}
                        >
                          <div
                            className="row-wise"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                              if (!val.subMenu) {
                                navigate(val.path);
                                setSidebarIsOpen(false);
                                dispatch(setSidebarIsOpens(false));
                              }
                              setIsActive((prev) => {
                                const newState = {
                                  ...prev,
                                  [ind]: !prev[ind],
                                };
                                localStorage.setItem(
                                  "submenuState",
                                  JSON.stringify(newState)
                                ); // Store submenu state
                                return newState;
                              });
                              dispatch(setTableLength([]));
                              dispatch(setPageCount(1));
                              localStorage.setItem("pageCount", 1);
                            }}
                          >
                            <img
                              src={
                                val.activeTab?.includes(
                                  window.location.pathname
                                )
                                  ? val.activeIcon
                                  : val.icon
                              }
                              alt=""
                            />
                            {isOpen && val.title} {val.title === "Support" ? <span style={{ borderRadius: "50%", padding: ticketCount > 9 ? "1px 2px" : "0 4px", marginLeft: "6px"  }}> ->{ticketCount}</span> : ""}
                          </div>
                          {val.subMenu && (
                            <img
                              className="arrow-img"
                              src={
                                isSubMenuActive
                                  ? val.activeTab?.includes(
                                      window.location.pathname
                                    )
                                    ? darkArrowSide
                                    : darkTopAr
                                  : downArrow
                              }
                              alt=""
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent this click from bubbling to the MenuRow onClick
                                setIsActive((prev) => {
                                  const newState = {
                                    ...prev,
                                    [ind]: !prev[ind],
                                  };
                                  localStorage.setItem(
                                    "submenuState",
                                    JSON.stringify(newState)
                                  ); // Store submenu state
                                  return newState;
                                });
                                localStorage.setItem(
                                  "firstMenuActive",
                                  encryptedFirstMenuActive
                                );

                                localStorage.removeItem("mainClientId");
                                localStorage.setItem(
                                  "secondMenuActive",
                                  encryptedSecondMenuActive
                                );
                                dispatch(
                                  setFirstMenu({
                                    isClient: true,
                                    isBilling: false,
                                    isMicrosite: false,
                                    isCourses: false,
                                    isSettings: false,
                                    isClientAdminSpoc: false,
                                    isNotes: false,
                                  })
                                );
                                dispatch(
                                  setSecondMenu({
                                    courseDetail: true,
                                    learnerDetail: false,
                                    policyDocuments: false,
                                    committee: false,
                                    isReminder: false,
                                    courseAssignments: false,
                                  })
                                );

                                dispatch(setCourseLangId(null));
                                dispatch(setCourseMasterId(null));
                                dispatch(setCourseTypo(false));
                                dispatch(
                                  setEpisodeDatao({
                                    title: "",
                                    url: "",
                                    fileSize: "",
                                    status: "",
                                    courseEpisodeeName: "",
                                    courseDescription: "",
                                    courseTags: [],
                                    videoTime: "",
                                  })
                                );
                              }}
                            />
                          )}
                        </SS.MenuRow>
                        {isSubMenuActive && (
                          <SS.SubMenuBox
                            isActive={isSubMenuActive}
                            themeColor={theme}
                          >
                            {val.subMenu?.map((subItem, subIndex) => {
                              return SubAccessCheck(subItem.key) ? (
                                <SS.ActiveSub
                                  themeColor={theme}
                                  key={subIndex}
                                  activeColor={subItem.activePathTab?.includes(
                                    window.location.pathname
                                  )}
                                  onClick={() => {
                                    navigate(subItem.pathName);
                                    setSidebarIsOpen(false);
                                    dispatch(setSidebarIsOpens(false));
                                    dispatch(setTableLength([]));
                                    dispatch(setPageCount(1));
                                    localStorage.setItem("pageCount", 1);
                                  }}
                                >
                                  {subItem.label}
                                </SS.ActiveSub>
                              ) : null;
                            })}
                          </SS.SubMenuBox>
                        )}
                      </SS.MenuBox>
                    ) : null;
                  })}
                {/* <SS.MenuBox
                  style={{
                    marginTop: "1.2rem",
                    marginBottom: "1rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  <SS.MenuRow
                    themeColor={theme}
                    onClick={() => {
                      navigate("/notification");
                      setSidebarIsOpen(false);
                      dispatch(setSidebarIsOpens(false));
                    }}
                  >
                    <div className="row-wise">
                      <img src={theme ? bellDarkIcon : bellIcon} alt="" />
                      Notifications
                    </div>
                  </SS.MenuRow>
                </SS.MenuBox> */}
              </SS.SidebarList>
            </SS.LogoListBox>
            <SS.BottomContainer>
              <SS.ComMenuBoxCon
                themeColor={theme}
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={theme ? lightMoon : lightSun} alt="" />
                  Theme
                </div>
                <SS.DefaultToggle>
                  <Switch
                    rounded={true}
                    isToggled={theme}
                    bgColorChecked="#F6591C"
                    roundColorChecked="#ffffff"
                    border={
                      theme ? "1px solid transparent" : "1px solid #D7DAE0"
                    }
                    themeColor={theme}
                    wiDth={"40px"}
                    bgColorUnChecked={theme?.isDark ? "#1F242F" : "#D7DAE0"}
                    roundColorUnChecked="#ffffff"
                    onToggle={() => {
                      const newTheme = !theme;
                      localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
                      dispatch(setIsDark(newTheme));
                    }}
                  />
                </SS.DefaultToggle>
              </SS.ComMenuBoxCon>
              <SS.LogOutBoxResp themeColor={theme} onClick={handleConfirm}>
                <NS.AvatarSidebar
                // onClick={() => {
                //   setIsProfileOpen(!isProfileOpen);
                // }}
                >
                  <img src={profileData?.profileImage || AvatarIcon} alt="" />
                </NS.AvatarSidebar>
                <CS.TitleColumn>
                  <CS.ColumnTitle themeColor={theme}>
                    {" "}
                    {profileData?.firstName} {profileData?.lastName}
                  </CS.ColumnTitle>
                  <CS.ColumnEmail
                    style={{
                      whiteSpace: "nowrap",
                      width: "130px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    themeColor={theme}
                  >
                    {profileData?.email}
                  </CS.ColumnEmail>
                </CS.TitleColumn>
                <img className="log-out" src={logoutLogo} alt="" />
              </SS.LogOutBoxResp>
            </SS.BottomContainer>
          </SS.InnerSidebar>
          <SS.InnerSidebarBlank
            onClick={() => {
              dispatch(setSidebarIsOpens(false));
            }}
          ></SS.InnerSidebarBlank>
        </SS.SidebarContainerResp>
      ) : (
        ""
      )}

      <Modal
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection>
              <SEC.Title themeColor={theme}>Are you sure!</SEC.Title>
              <SEC.Subtitle themeColor={theme}>
                Are you sure you want to leave this page?
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                >
                  Cancel
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  Yes, Logout
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
      <ModalSuccess
        isOpen={showsucess}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBox>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBox>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.Title themeColor={theme}>Great!</SEC.Title>
            {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
            <SEC.Subtitle themeColor={theme}>
              You have successfully logged out of the application.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
                localStorage.removeItem("rainDataToken");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("rainRMUserData");
                localStorage.removeItem("isToggle");
                localStorage.removeItem("firstMenuActive");
                localStorage.removeItem("secondMenuActive");
                localStorage.removeItem("pageCount");
                localStorage.removeItem("rainTheme");
                dispatch(setUserData(""));
                navigate("/");
              }}
            >
              OK
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default Sidebar;
