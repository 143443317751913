import styled from "styled-components/macro";
import backOne from "../assets/images/backone.png";
import backTwo from "../assets/images/backtwo.png";
import OtpInput from "react-otp-input";

export const ParentBox = styled.div`
  width: 100%;
  /* min-width: 1440px; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
`;
export const MainContainer = styled.div`
  width: 100%;
  /* min-width: 1440px; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
export const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-width: 1440px; */
`;
export const BackContainer = styled.div`
  width: 100%;
  /* height: 100%; */
  /* min-width: 1440px; */
  min-height: 100vh;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-size: contain;
  padding: 2rem;
  /* overflow: hidden !important; */
  /* background-repeat: no-repeat; */
  /* min-height: 770px; */
  background-image: url(${backOne});

  .back-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  @media (max-width: 769px) {
    flex-direction: column;
  }
  @media (max-width: 769px) {
    background-size: cover;
  }
`;
export const LoginButton = styled.button`
  background-color: #f6591c;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  color: #ffffff;
  width: 100%;
  outline: none;
  border: none;
  padding: 0.9rem 0;
  margin: 1.6rem 0;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  @media (max-width: 351px) {
    font-size: 1rem;
  }

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }
`;
export const LoginButtonTwo = styled.button`
  background-color: transparent;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  color: #ffffff;
  width: 100%;
  outline: none;
  border: none;
  padding: 0.9rem 0;
  margin: 1.6rem 0;
  cursor: pointer;
  border: 1px solid #d7dae0;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 20px;
    width: 30px;
    object-fit: contain;
    margin-right: 7px;
  }
`;
export const OTPBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1.5rem;
  flex-direction: column;
`;
export const OTPBoxTwo = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1.5rem;
  flex-direction: column;
`;
export const OtpInputCus = styled(OtpInput)`
  width: 50% !important;
  height: 60px;
  border-radius: 7px;
  /* border: 0px; */
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
  outline: 10px solid red;
  border: 10px solid red;

  @media (max-width: 440px) {
    height: 50%;
    width: 50%;
  }
`;
export const PrivateContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  @media (max-width: 769px) {
    flex-direction: column;
  }

  @media screen and (max-width: 480px) {
  }
`;
export const PrivateInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 1440px;
  display: flex;
  justify-content: center;
  /* background-color: aqua; */
  @media (max-width: 1445px) {
    min-width: 100%;
    justify-content: ${(props) =>
      props.sidebarToggle ? "center" : "space-between"};
  }
`;
export const PrivateFirstContainer = styled.div`
  height: 100%;
  flex: ${(props) => (props.sidebarToggle ? "0 0 85px" : "0 0 230px")};
  /* min-width: 230px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1423px) {
    /* flex: 0; */
    width: 20%;
  }
  /* @media (max-width: 1031px) {
    width: 22%;
  } */
  @media (max-width: 769px) {
    flex: 0;
    width: auto;
  }
`;
export const PrivateSecondContainer = styled.div`
  height: 100%;
  /* width: 85%; */
  max-width: 1210px;
  min-width: 1210px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden !important;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  @media (max-width: 1423px) {
    min-width: auto;
    max-width: 100%;
    width: 80%;
  }
  /* @media (max-width: 1031px) {
    min-width: 80%;
  } */
  @media (max-width: 769px) {
    min-width: 100%;
  }
`;

export const NoPermit = styled.div`
  width: 100%;
  height: 100;
  margin: auto;
  font-size: 2rem;
  font-weight: 600;
  color: red;
  text-align: center;
`;
export const ComButton = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 1rem;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;
export const ImperBox = styled.div`
  margin-right: 1rem;
  cursor: pointer;
  p {
    background-color: #fdede7;
    padding: 2px 5px;
    color: #F05D24;
    font-weight: 500;
    font-size: 0.7rem;
  }
`
export const ComButtons = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }

  @media (max-width: 385px) {
    img {
      height: 13px;
      width: 13px;
      object-fit: contain;
    }
  }
`;
export const ComButtonClient = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin-right: 10px;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
`;
export const UploadComButtons = styled.div`
  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
  }
`;
export const ComSignal = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 1rem;
  cursor: pointer;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;
export const DummyBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const DummyImageBox = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 8px;
  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const DummyTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #24262d;
  margin-bottom: 0.7rem;
`;
export const DummyDesc = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  color: #667085;
  margin-bottom: 1.5rem;
`;
export const StatusButton = styled.div`
  color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#73E2A7"
        : "#17B26A"
      : props.themeColor
      ? "#94969C"
      : "#667085"};
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#0A5B39"
        : "#EDFCF3"
      : props.themeColor
      ? "#333741"
      : "#F6F7F9"};
  font-size: 0.8rem;
  text-transform: capitalize;
  border-radius: 16px;
  text-align: center;
  padding: 4px 8px;
  font-weight: 500;
`;
export const StatusButtonTwo = styled.div`
width: 100%;

img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  cursor: pointer;
}
`;
export const StatusButtonBulk = styled.div`
  color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#73E2A7"
        : "#17B26A"
      : props.themeColor
      ? "#94969C"
      : "#667085"};
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#0A5B39"
        : "#EDFCF3"
      : props.themeColor
      ? "#333741"
      : "#F6F7F9"};
  font-size: 0.8rem;
  text-transform: capitalize;
  border-radius: 4px;
  text-align: center;
  padding: 4px 8px;
  font-weight: 500;
`;
export const StatusButtons = styled.div`
  color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#73E2A7"
        : "#17B26A"
      : props.themeColor
      ? "#94969C"
      : "#667085"};
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#0A5B39"
        : "#EDFCF3"
      : props.themeColor
      ? "#333741"
      : "#F6F7F9"};
  font-size: 0.8rem;
  text-transform: capitalize;
  border-radius: 16px;
  text-align: center;
  padding: 7px 14px;
  font-weight: 500;
`;
export const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const ColumnTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#101828")};
  font-weight: 500;
  font-size: 0.8rem;
`;
export const ColumnTitles = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
  font-size: 0.8rem;
`;
export const ColumnEmail = styled.div`
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  font-size: 0.8rem;
  text-transform: none !important;

  @media (max-width: 769px) {
    margin-top: 5px;
  }
`;
export const ColImage = styled.img`
  height: 18px;
  width: 18px;
  object-fit: contain;
`;
export const LottieLoadCon = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .div {
  }
`;
