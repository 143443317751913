import dashLogo from "../../assets/images/sidebarIcon/lightDashboard.png";
import dashActiveLogo from "../../assets/images/sidebarIcon/lightDashboard.png";
import categoryLogo from "../../assets/images/sidebarIcon/lightCategory.png";
import activeCategory from "../../assets/images/sidebarIcon/activeCategory.png";
import courseLogo from "../../assets/images/sidebarIcon/lightCourse.png";
import darkCourse from "../../assets/images/sidebarIcon/darkCourse.png";
import dashDark from "../../assets/images/sidebarIcon/dashDark.png";
import feedbackActiveLogo from "../../assets/images/sidebarIcon/lightFeedback.png";
import darkFeedback from "../../assets/images/sidebarIcon/darkFeedback.png";
import lightLanguage from "../../assets/images/sidebarIcon/lightLanguage.png";
import darkLanguage from "../../assets/images/sidebarIcon/darkLanguage.png";
import lightQuiz from "../../assets/images/sidebarIcon/lightQuiz.png";
import darkQuiz from "../../assets/images/sidebarIcon/darkQuiz.png";
import darkManage from "../../assets/images/sidebarIcon/darkManage.png";
import lightManage from "../../assets/images/sidebarIcon/lightManage.png";
import darkUser from "../../assets/images/sidebarIcon/darkUser.png";
import lightUser from "../../assets/images/sidebarIcon/lightUser.png";
import darkManageMomo from "../../assets/images/sidebarIcon/darkManageMomo.png";
import lightCustomer from "../../assets/images/sidebarIcon/lightCustomer.png";
import lightSurvey from "../../assets/images/sidebarIcon/lightSurvey.png";
import darkSurvey from "../../assets/images/sidebarIcon/darkSurvey.png";
import darkBulk from "../../assets/images/sidebarIcon/darkBulk.png";
import lightBulk from "../../assets/images/sidebarIcon/lightBulk.png";
import darkReport from "../../assets/images/sidebarIcon/darkReport.png";
import lightReport from "../../assets/images/sidebarIcon/lightReport.png";
import darkBadge from "../../assets/images/sidebarIcon/darkBadge.png";
import lightBadge from "../../assets/images/sidebarIcon/lightBadge.png";
import lightCertificates from "../../assets/images/sidebarIcon/lightCertificates.png";
import darkCertificate from "../../assets/images/sidebarIcon/darkCertificate.png";
import darkConfigure from "../../assets/images/sidebarIcon/darkConfigure.png";
import lightConfigure from "../../assets/images/sidebarIcon/lightConfigure.png";
import darkAudit from "../../assets/images/sidebarIcon/darkAudit.png";
import lightAudit from "../../assets/images/sidebarIcon/lightAudit.png";
import darkSupport from "../../assets/images/sidebarIcon/darkSupport.png";
import lightSupport from "../../assets/images/sidebarIcon/lightSupport.png";
import lightMaster from "../../assets/images/sidebarIcon/lightMaster.png";
import darkMaster from "../../assets/images/sidebarIcon/darkMaster.png";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    activeTab: ["/dashboard"],
    icon: dashLogo,
    activeIcon: dashDark,
    moduleName: null,
    key: ["dashboard"],
  },
  {
    title: "Masters",
    path: "/department",
    activeTab: [
      "/department",
      "/add-department",
      "/designation",
      "/add-designation",
      "/industry-management",
      "/add-industry",
      "/city-management",
      "/user-type",
      "/add-user-type",
      "/add-bulk-industry",
      "/add-bulk-user-type",
      "/add-bulk-department",
      "/add-bulk-designation",
      "/template-management",
      "/add-template",
    ],
    icon: lightMaster,
    activeIcon: darkMaster,
    moduleName: null,
    key: ["department", "designation", "industry", "user_type"],
    subMenu: [
      {
        label: "Department",
        pathName: "/department",
        activePathTab: [
          "/department",
          "/add-department",
          "/add-bulk-department",
        ],
        key: "department",
      },
      {
        label: "Designation",
        pathName: "/designation",
        activePathTab: [
          "/designation",
          "/add-designation",
          "/add-bulk-designation",
        ],
        key: "designation",
      },
      {
        label: "Industry",
        pathName: "/industry-management",
        activePathTab: [
          "/industry-management",
          "/add-industry",
          "/add-bulk-industry",
        ],
        key: "industry",
      },
      {
        label: "User Type",
        pathName: "/user-type",
        activePathTab: ["/user-type", "/add-user-type", "/add-bulk-user-type"],
        key: "user_type",
      },
      // {
      //   label: "Tempalte",
      //   pathName: "/template-management",
      //   activePathTab: ["/template-management", "/add-template"],
      //   key: "template",
      // },
    ],
  },
  {
    title: "Manage Access",
    path: "/role",
    activeTab: ["/role", "/add-role", "/user", "/add-user"],
    icon: lightUser,
    activeIcon: darkUser,
    moduleName: null,
    key: ["role", "user"],
    subMenu: [
      {
        label: "Add Role",
        pathName: "/role",
        activePathTab: ["/role", "/add-role"],
        key: "role",
      },
      {
        label: "Add User",
        pathName: "/user",
        activePathTab: ["/user", "/add-user"],
        key: "user",
      },
    ],
  },
  {
    title: "Language",
    path: "/language-management",
    activeTab: ["/language-management", "/add-language"],
    icon: lightLanguage,
    activeIcon: darkLanguage,
    moduleName: "languages",
    subMenu: null,
    key: ["language"],
  },
  {
    title: "Category",
    path: "/category-management",
    activeTab: ["/category-management", "/add-category"],
    icon: categoryLogo,
    activeIcon: activeCategory,
    moduleName: "categories",
    subMenu: null,
    key: ["category"],
  },
  {
    title: "Course",
    path: "/course-management",
    activeTab: ["/course-management", "/add-course"],
    icon: courseLogo,
    activeIcon: darkCourse,
    moduleName: "courses",
    subMenu: null,
    key: ["course"],
  },
  {
    title: "Quizzes",
    path: "/assessment-quizz-management",
    activeTab: [
      "/assessment-quizz-management",
      "/add-assessment-quizz",
      "/interactive-quizz-management",
      "/add-interactive-quizz",
      "/add-free-quizz",
    ],
    icon: lightQuiz,
    activeIcon: darkQuiz,
    moduleName: null,
    key: ["interactive_quiz", "assessment_quiz"],
    subMenu: [
      {
        label: "Interactive Quiz",
        pathName: "/interactive-quizz-management",
        activePathTab: [
          "/interactive-quizz-management",
          "/add-interactive-quizz",
        ],
        key: "interactive_quiz",
      },
      {
        label: "Assessment Quiz",
        pathName: "/assessment-quizz-management",
        activePathTab: [
          "/assessment-quizz-management",
          "/add-assessment-quizz",
          "/add-free-quizz",
        ],
        key: "assessment_quiz",
      },
    ],
  },
  {
    title: "Feedback Form",
    path: "/feedback-from",
    activeTab: ["/feedback-from", "/add-feedback"],
    icon: feedbackActiveLogo,
    activeIcon: darkFeedback,
    moduleName: "feeedback_forms",
    subMenu: null,
    key: ["feedback_form"],
  },

  {
    title: "Certificate",
    path: "/certificate",
    activeTab: [
      "/learner-certificate",
      "/special-certificate",
      "/edit-learner-certificate",
      "/add-special-certificate",
      "/special-certificate-view-log",
    ],
    icon: lightCertificates,
    activeIcon: darkCertificate,
    moduleName: null,
    key: ["learner_certificate", "special_certificate"],
    subMenu: [
      {
        label: "Learner Certificate",
        pathName: "/learner-certificate",
        activePathTab: ["/learner-certificate", "/edit-learner-certificate"],
        key: "learner_certificate",
      },
      {
        label: "Special Certificate",
        pathName: "/special-certificate",
        activePathTab: [
          "/special-certificate",
          "/add-special-certificate",
          "/special-certificate-view-log",
        ],
        key: "special_certificate",
      },
    ],
  },
  {
    title: "Manage Client",
    path: "/client-management",
    activeTab: [
      "/client-management",
      "/add-client",
      "/client-groups",
      "/add-group",
      "/learner-management",
      "/add-learner"
    ],
    icon: lightCustomer,
    activeIcon: darkManageMomo,
    moduleName: null,
    key: ["clients", "client_groups"],
    subMenu: [
      {
        label: "Clients",
        pathName: "/client-management",
        activePathTab: ["/client-management", "/add-client"],
        key: "clients",
      },
      {
        label: "Client Groups",
        pathName: "/client-groups",
        activePathTab: ["/add-group", "/client-groups"],
        key: "client_groups",
      },
      {
        label: "Learner",
        pathName: "/learner-management",
        activePathTab: ["/learner-management", "/add-learner"],
        key: "client_groups",
      },
    ],
  },
  {
    title: "Bulk Delete",
    path: "/inactive-learner",
    activeTab: [
      "/inactive-learner",
      "/course-delete",
      "/episode-delete",
      "/category-delete",
    ],
    icon: lightBulk,
    activeIcon: darkBulk,
    moduleName: null,
    key: [
      "delete_inactive_learners",
      "delete_categories",
      "delete_courses",
      "delete_episodes",
    ],
    subMenu: [
      {
        label: "Delete/Inactive Learners",
        pathName: "/inactive-learner",
        activePathTab: ["/inactive-learner"],
        key: "delete_inactive_learners",
      },
      {
        label: "Delete Categories",
        pathName: "/category-delete",
        activePathTab: ["/category-delete"],
        key: "delete_categories",
      },
      {
        label: "Delete Courses",
        pathName: "/course-delete",
        activePathTab: ["/course-delete"],
        key: "delete_courses",
      },
      {
        label: "Delete Episodes",
        pathName: "/episode-delete",
        activePathTab: ["/episode-delete"],
        key: "delete_episodes",
      },
    ],
  },

  {
    title: "Manage Content",
    path: "/manage-content",
    activeTab: [
      "/content-management",
      "/interest-mapping",
      "/music-file",
      "/add-music",
      "/add-interest",
    ],
    icon: lightManage,
    activeIcon: darkManage,
    moduleName: null,
    key: ["login_banner_image", "interest_mapping", "music_file"],
    subMenu: [
      {
        label: "Login Banner Image",
        pathName: "/content-management",
        activePathTab: ["/content-management"],
        key: "login_banner_image",
      },
      {
        label: "Interest Mapping",
        pathName: "/interest-mapping",
        activePathTab: ["/interest-mapping", "/add-interest"],
        key: "interest_mapping",
      },
      {
        label: "Music File",
        pathName: "/music-file",
        activePathTab: ["/music-file", "/add-music"],
        key: "music_file",
      },
    ],
  },

  {
    title: "Configuration",
    path: "/configuration",
    activeTab: ["/configuration"],
    icon: lightConfigure,
    activeIcon: darkConfigure,
    moduleName: null,
    subMenu: null,
    key: ["configuration"],
  },
  {
    title: "Survey",
    path: "/client-management",
    activeTab: [
      "/survey-groups",
      "/add-survey-groups",
      "/survey-forms",
      "/add-survey-form",
      "/survey-view-log",
    ],
    icon: lightSurvey,
    activeIcon: darkSurvey,
    moduleName: null,
    key: ["survey_groups", "survey_forms"],
    subMenu: [
      {
        label: "Survey Groups",
        pathName: "/survey-groups",
        activePathTab: ["/survey-groups", "/add-survey-groups"],
        key: "survey_groups",
      },
      {
        label: "Survey Forms",
        pathName: "/survey-forms",
        activePathTab: [
          "/survey-forms",
          "/add-survey-form",
          "/survey-view-log",
        ],
        key: "survey_forms",
      },
    ],
  },
  {
    title: "Badges",
    path: "/badges",
    activeTab: ["/badges", "/edit-badges", "/add-badges"],
    icon: lightBadge,
    activeIcon: darkBadge,
    moduleName: null,
    subMenu: null,
    key: ["Badges"],
  },
  {
    title: "Audit Logs",
    path: "/audit-logs",
    activeTab: ["/audit-logs"],
    icon: lightAudit,
    activeIcon: darkAudit,
    moduleName: null,
    subMenu: null,
    key: ["audit_log"],
  },
  {
    title: "Reports",
    path: "/master-reports",
    activeTab: [
      "/master-reports",
      "/course-reports",
      "/client-reports",
      "/agreement-reports",
      "/industry-reports",
      "/roll-out-reports",
      "/progress-reports",
      "/audit-reports",
      "/add-delete-learner-reports",
      "/feedback-reports",
      "/entry-reports",
      "/course-shedule",
      "/course-add-shedule",
      "/client-shedule",
      "/client-add-shedule",
      "/agreement-shedule",
      "/agreement-add-shedule",
      "/industry-shedule",
      "/industry-add-shedule",
      "/roll-out-shedule",
      "/roll-out-add-shedule",
      "/progress-shedule",
      "/progress-add-shedule",
      "/audit-shedule",
      "/audit-add-shedule",
      "/add-delete-learner-shedule",
      "/add-delete-learner-add-shedule",
      "/feedback-shedule",
      "/feedback-add-shedule",
      "/master-shedule",
      "/master-add-shedule",
      "/entry-shedule",
      "/entry-add-shedule",
    ],
    icon: lightReport,
    activeIcon: darkReport,
    moduleName: null,
    subMenu: [
      {
        label: "Master Reports",
        pathName: "/master-reports",
        activePathTab: [
          "/master-reports",
          "/master-shedule",
          "/master-add-shedule",
        ],
      },
      {
        label: "Course wise Report (summary/detailed report)",
        pathName: "/course-reports",
        activePathTab: [
          "/course-reports",
          "/course-shedule",
          "/course-add-shedule",
        ],
      },
      {
        label: "Client Specific Report on Licence",
        pathName: "/client-reports",
        activePathTab: [
          "/client-reports",
          "/client-shedule",
          "/client-add-shedule",
        ],
      },
      {
        label: "Agreement Date Along With The RM Sales SPOC",
        pathName: "/agreement-reports",
        activePathTab: [
          "/agreement-reports",
          "/agreement-shedule",
          "/agreement-add-shedule",
        ],
      },
      {
        label: "Industry Wise Report",
        pathName: "/industry-reports",
        activePathTab: [
          "/industry-reports",
          "/industry-shedule",
          "/industry-add-shedule",
        ],
      },
      {
        label: "Feedback Wise Report",
        pathName: "/feedback-reports",
        activePathTab: [
          "/feedback-reports",
          "/feedback-shedule",
          "/feedback-add-shedule",
        ],
      },
      {
        label: "Roll Out Date Report",
        pathName: "/roll-out-reports",
        activePathTab: [
          "/roll-out-reports",
          "/roll-out-shedule",
          "/roll-out-add-shedule",
        ],
      },
      {
        label: "Progress Report",
        pathName: "/progress-reports",
        activePathTab: [
          "/progress-reports",
          "/progress-shedule",
          "/progress-add-shedule",
        ],
      },
      {
        label: "Addition and Deletions of Licenses Client Wise",
        pathName: "/add-delete-learner-reports",
        activePathTab: [
          "/add-delete-learner-reports",
          "/add-delete-learner-shedule",
          "/add-delete-learner-add-shedule",
        ],
      },
      {
        label: "Audit Report User Wise/ Client Wise",
        pathName: "/audit-reports",
        activePathTab: [
          "/audit-reports",
          "/audit-shedule",
          "/audit-add-shedule",
        ],
      },
      {
        label: "Entry-Wise Report",
        pathName: "/entry-reports",
        activePathTab: [
          "/entry-reports",
          "/entry-shedule",
          "/entry-add-shedule",
        ],
      },
    ],
  },

  {
    title: "Support",
    path: "/support",
    activeTab: ["/support"],
    icon: lightSupport,
    activeIcon: darkSupport,
    moduleName: null,
    subMenu: null,
    key: "support",
  },
];
