import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import customReducer from "./store/reducers";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./pages/public/adminLogin/AzureLogin/authConfig";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { socket, SocketContext } from "./utils/socket";
import { API_URL_SOCKET } from "./pages/constants/Statics";

const store = configureStore({
  reducer: customReducer, // Your root reducer
});
const client = new ApolloClient({
  uri: API_URL_SOCKET,
  cache: new InMemoryCache(),
});

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <SocketContext.Provider value={socket}>
  <ApolloProvider client={client}>
    <MsalProvider instance={msalInstance}>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </MsalProvider>
  </ApolloProvider>
  // </SocketContext.Provider>
);
