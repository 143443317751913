import styled from "styled-components";

export const Switchlabel = styled.label.attrs({
  className: "switch",
})`
  position: relative;
  display: inline-block;
  width: ${({ wiDth }) => (wiDth ? wiDth : "80px !important")};
  height: 20px;
`;

export const SwitchInput = styled.input.attrs({
  type: "checkbox",
})`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider_slide {
    background-color: ${({ bgColorChecked }) =>
      bgColorChecked ? bgColorChecked : "#ffffff"};
    /* background-color: #ffffff; */
  }

  &:checked + .slider_slide:before {
    transform: translateX(19px);
    background-color: ${({ roundColorChecked }) =>
      roundColorChecked ? roundColorChecked : "#A91F6E"};
    /* background-color: #A91F6E; */
  }
`;

export const SwitchSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 2;
  /* right: 0; */
  bottom: 0;
  margin-left: ${({ marLeft }) => (marLeft ? "-1rem" : "0")};

  border: ${({ border }) => (border ? border : "1px solid #ffffff")};
  background-color: ${({ bgColorUnChecked }) =>
    bgColorUnChecked ? bgColorUnChecked : "#A91F6E"};
  /* background-color: #A91F6E; */

  transition: 0.3s;

  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 1px;
    background-color: ${({ roundColorUnChecked }) =>
      roundColorUnChecked ? roundColorUnChecked : "#ffffff"};
    transition: 0.3s;
  }

  /* &:checked + .slider_slide {
        background-color: #ffffff;
    }

    &:checked + .slider_slide:before {
        transform: translateX(19px);
        background-color: #A91F6E;
    } */

  &.rounded {
    border-radius: 34px !important;
    border: ${({ themeColor }) =>
      themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  }

  &.rounded::before {
    border-radius: 50%;
    box-shadow: 0px 1px 2px 0px #1018280f;

    box-shadow: 0px 1px 3px 0px #1018281a;
  }
`;
